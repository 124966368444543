<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Tags</span>
        </h3>
        <div class="card-toolbar">
          <router-link
            to="/tags/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Map/Position.svg" />
            </span>
            <span>Add New Tag</span>
          </router-link>
        </div>
      </div>

      <div class="card-body py-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span @click="orderTagsByName" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': nameOrder.isActive }"
                    >
                      Name</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': nameOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="nameOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th class="text-right pr-6">
                  <span>{{ $t('GENERAL.ACTIONS') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tag, key) in tags" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/tags/edit/' + tag.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ tag.name }}</router-link
                  >
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <router-link
                    :to="'/tags/edit/' + tag.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click="deleteEntity(tag.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'TagsList',
  data() {
    return {
      tags: [],
      isTagsLoaded: false,
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Tags', route: '' }])

    if (this.currentUserRole === 'ROLE_ADMIN') {
      ApiService.get(
        'tags',
        `?company=${this.currentUserCompany}&order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.tags = [...data['hydra:member']]
        this.isTagsLoaded = true
      })
    }
  },
  methods: {
    orderTagsByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isTagsLoaded = false
      this.tags = []

      ApiService.get(
        'tags',
        `?company=${this.currentUserCompany}&order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.tags = [...data['hydra:member']]
        this.isTagsLoaded = true
      })
    },
  },
}
</script>
